import React from "react"

export default function Header() {
    return (
        <div className="container church-worthship"> 
            <div className="row">
                <div className="col form--header">
                    <h1>Orthodox church worship</h1>
                </div>
            </div>
        </div>
       
    )
}

